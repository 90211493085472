<template>
  <div class="static_home">
    <div class="section flex-row" id="presentation">
      <div class="elem-section small_section">
        <h2 class="fnt-text txt-violet bold" id="anim-word">
          <div class="cont-word-first">
            <!-- <div v-for="word in wordFirstPart" :key="word">{{word}}</div> -->
            {{wordFirst}}
          </div>
          <span>&nbsp;jobs are special, so are your needs.</span>
        </h2>
        <h3 class="title bigger bold mb3 mt3">
          Work...<br>At the change
        </h3>
        <p class="fnt-text bigger">
          Discover the platform made for this
        </p>
        <div class="cont-btn-home mt10">
          <a class="btn-green big radius">
            Free trial<sup>*</sup>
          </a>
          <span class="mention">*No credit card required</span>
        </div>
      </div>
      <div class="elem-section big_section">
        <img class="bigger" :src="require('@/assets/home/home_visual_section01.svg')">
      </div>
      <div class="invite-scroll">
        <span>Scroll</span>
        <div class="cont-elem-scroll">
          <div class="free-scroll"></div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="section flex-col" id="overview">
      <div class="radius top-sect-02">
        <div class="flex-row">
          <div class="elem-section big_section">
            <img class="bigger" :src="require('@/assets/home/home_visual_section02.svg')">
          </div>
          <div class="elem-section small_section">
            <h2 class="title txt-white bold mb3">
              All you need in<br>one place
            </h2>
            <p class="fnt-text txt-grey-purple mt8">
              Quasting is the first facilitating platform for NextGen Tech professionals :
              FinTech, AI, DataScience, AR/VR, etc....
              (An online service crafted to change the way the (fast growing)
              demand for the jobs of the future meet the supply.)
            </p>
            <p class="fnt-text txt-grey-purple mt2 mb8">
              If you’re a Company looking for a NextGen Tech talent, if you’re a Professional
              looking for your new career challenge or, simply, you’re curious about FinTech, AI,
              DataScience, Blockchain, Augmented and Virtual Reality...Quasting is here for you.
            </p>
            <div class="cont-btn">
              <div class="btn-violet radius big">
                I'm a talent
              </div>
              <p class="fnt-text txt-grey-purple blk">or</p>
              <div class="btn-violet radius big">
                I'm a company
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-sect-02 flex-row">
        <div class="bot-sect-02 left">
          <h3 class="txt-white bold">
            Hello <span class="txt-green">Companies</span>,
          </h3>
          <p class="txt-white fnt-text">
            Everything you need to hire the best human resources
            (or team member). Defining, customizing, simplifying
            and speeding up your recruitment process, from the job
            description to the tests, from the hiring flow to the video
            interviews.
          </p>
          <div class="cont-btn">
            <a class="btn-line green">Learn more ></a>
          </div>
        </div>
        <div class="bot-sect-02 right">
          <h3 class="txt-white bold">
            Hello <span class="txt-green">Professionals</span>,
          </h3>
          <p class="txt-white fnt-text">
            Create and boost your profile, highlight your skills, apply
            effectively with the most relevant information,
            find the best job for you and get hired.
          </p>
          <div class="cont-btn">
            <a class="btn-line green">Learn more ></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Optimize -->
    <div class="section" id="optimize">
      <h2 class="title bold mb3 center">Optimize</h2>
      <p class="fnt-text center txt-grey-purple mw60">
        All at once, Quasting combines processes and tools in one single web platform
        allowing you to easily match “supply and demand” for FinTech jobs.
        lever processes allow more effective decisions in less time.
      </p>
      <div class="cont-optimize">
        <div class="elem-optimize">
          <h3 class="title-optimize mt6 mb3 bold center">
            Your <span class="txt-violet">time</span>
          </h3>
          <p class="fnt-text center txt-grey-purple">
            Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </p>
          <div class="visual-optimize visual01 mt6">
            <img class="bg-visual-opti radius" :src="require('@/assets/home/bg_optimize.png')">
            <img :src="require('@/assets/home/illu_time.png')">
          </div>
        </div>
        <div class="elem-optimize">
          <h3 class="title-optimize mt6 mb3 bold center">
            Your <span class="txt-violet">process</span>
          </h3>
          <p class="fnt-text center txt-grey-purple">
            Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </p>
          <div class="visual-optimize visual02 mt6">
            <img class="bg-visual-opti radius" :src="require('@/assets/home/bg_optimize.png')">
            <img :src="require('@/assets/home/illu_process.png')">
          </div>
        </div>
        <div class="elem-optimize">
          <h3 class="title-optimize mt6 mb3 bold center">
            Your <span class="txt-violet">decision</span>
          </h3>
          <p class="fnt-text center txt-grey-purple">
            Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </p>
          <div class="visual-optimize visual03 mt6">
            <img class="bg-visual-opti radius" :src="require('@/assets/home/bg_optimize.png')">
            <img :src="require('@/assets/home/illu_decision.png')">
          </div>
        </div>
      </div>
    </div>
    <!-- Improve -->
    <div class="section flex-row">
      <div class="flex-row">
        <div class="elem-section big_section">
          <img class="bigger leftdecal" :src="require('@/assets/home/home_visual_section04.svg')">
        </div>
        <div class="elem-section small_section pl5v">
          <h2 class="bold mb8 title">
            Improve<br>your team synergy
          </h2>
          <h3 class="bold txt-violet fnt-text bigger mb3">
            Quasting has been designed to ease, <br>simplify, smooth...
          </h3>
          <p class="fnt-text op50">
            The hiring process thanks to collaborative tools,
            allowing the different managers to operate autonomously on every single stage of
            the recruitment process.
            Thanks to the customizable process, team overview, online tests and video interview,
            team synergy will have a key role in hiring the best profiles.
          </p>
          <div class="cont-btn mt3 just-right">
            <a class="btn-line">
              Learn more >
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="section flex-row">
      <div class="elem-section small_section">
        <h2 class="bold mb8 title">
          Stop wasting<br>money
        </h2>
        <p class="fnt-text op50">
          Define your criteria, optimize and speed up your processes,
          get the best profiles... avoiding intermediaries: this is what cost efficiency means!
        </p>
        <div class="cont-btn mt3">
          <a class="btn-green big radius">
            Demo request
          </a>
        </div>
      </div>
      <div class="elem-section big_section">
        <img class="bigger" :src="require('@/assets/home/home_visual_section05.svg')">
      </div>
    </div>
    <!-- Numbers -->
    <div class="section">
      <div id="triggerAnimeChiffres"></div>
      <h2 class="title bold mb3 center">Some numbers...</h2>
      <p class="fnt-text center txt-grey-purple mw60">
        Lorem ipsum dolor sit amet, consectetur elit. Phasellus accumsan
        imperdiet nisl, eu vulputate ipsum rhoncus sit amet. Praesent elementum
        risus mauris, quis commodo tortor fermentum vel.
      </p>
      <div class="cont-number mt10">
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_finetech.svg')">
          </div>
          <h3 class="bold uppercase mt2">Fintech</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.finetech}}</div>
          <div class="label">startup</div>
        </div>
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_blockchain.svg')">
          </div>
          <h3 class="bold uppercase mt2">Blockchain</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.blockchain}}%</div>
          <div class="label">
            growth of blockchain<br>
            usage is expected<br>
            in fintech
          </div>
        </div>
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_data.svg')">
          </div>
          <h3 class="bold uppercase mt2">AI/Data science</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.ai}}%</div>
          <div class="label">
            of companies claim<br>
            to have a shortage<br>
            of AI experts<br>
            (McKinsey)
          </div>
        </div>
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_security.svg')">
          </div>
          <h3 class="bold uppercase mt2">Cyber security</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.cyber}}%</div>
          <div class="label">
            du lorem ipsum<br>
            dolor sit amet<br>
            (ce chiffre est<br>
            temporaire)
          </div>
        </div>
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_arvr.svg')">
          </div>
          <h3 class="bold uppercase mt2">AR/VR</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.ar}}M</div>
          <div class="label">
            AR/VR job in 2030
          </div>
        </div>
        <div class="elem-number">
          <div class="picto-number">
            <img class="bigger" :src="require('@/assets/home/picto_number_global.svg')">
          </div>
          <h3 class="bold uppercase mt2">Global</h3>
          <div class="separ mt2"></div>
          <div class="number txt-violet bold">{{startchiffres.global}}%</div>
          <div class="label">
            of employers hired<br>
            remotely (2020 - 2021)
          </div>
        </div>
      </div>
    </div>
    <!-- Features -->
    <div class="section radius sect-features" id="features">
      <img class="bg_feature" :src="require('@/assets/home/bg_feature.png')">
      <h2 class="title bold mb3 txt-white center">Main features...</h2>
      <div class="select-feature">
        <h3
          class="elem-select-feat radius-small bold center"
          @click="() => selectFeature('company')"
          :class="(featureSelected==='company')?'active':''"
        >
          Company
          <div class="arrow-down"></div>
        </h3>
        <h3
          class="elem-select-feat radius-small bold center no-margin"
          @click="() => selectFeature('talent')"
          :class="featureSelected==='talent'?'active':''"
        >
          Talent
          <div class="arrow-down"></div>
        </h3>
      </div>
      <div class="cont-feature radius"
        :class="(featureSelected==='company')?'company':'talent'"
      >
        <div id=""
          class="cont-feature-list"
          :class="(featureSelected==='company')?'active':''"
        >
          <div class="feature-list">
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Customize the process and the flow for each job offer
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Define the way you want to follow / monitor every stage<br>
                and application
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Organize real time video interviews and online technical tests
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Explore our "decision making" tools
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Maximize team synergy and the overall experience through<br>a collaborative area
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature_green.svg')">
              </div>
              <p class="txt-white">
                Increase your business network through the community
              </p>
            </div>
          </div>
          <div class="cont-btn"></div>
        </div>
        <div id=""
          class="cont-feature-list talent-feature"
          :class="featureSelected==='talent'?'active':''"
        >
          <div class="feature-list">
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature.svg')">
              </div>
              <p class="txt-white">
                Highlight your resume, skills and experience through the public profile<br>
                and apply effectively through the “à la carte” resume (create the best<br>
                application from preset content)
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature.svg')">
              </div>
              <p class="txt-white">
                Media Library (create and stock images, video and audio files that will<br>
                enhance your profile and applications)
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature.svg')">
              </div>
              <p class="txt-white">
                Follow / Monitor the progress of your applications
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature.svg')">
              </div>
              <p class="txt-white">
                Find which company is the best fit for you
              </p>
            </div>
            <div class="elem-feature">
              <div class="picto-feature">
                <img :src="require('@/assets/home/dot_feature.svg')">
              </div>
              <p class="txt-white">
                Increase your visibility and network via the Quasting Community
              </p>
            </div>
          </div>
          <div class="cont-btn"></div>
        </div>
      </div>
    </div>
    <!-- Jobs -->
    <div class="section" id="jobs">
      <h2 class="title bold mb3">
        An opportunity for you?
      </h2>
      <h3 class="fnt-text txt-grey-purple">
        Latest jobs in quasting.
      </h3>
      <div class="cont-global-offer mt10">
        <div class="cont-switch">
          <ToggleSwitch
          :labelOff="'Job'"
          :labelOn="'Talent'"
          :func="updateList"
        ></ToggleSwitch>
        </div>
        <div class="cont-list" v-if="whichlist == 'Job'">
          <splide class="list-job" :options="gridOptions" :extensions="extensions">
            <splide-slide v-for="job in fakeElem" :key="job"
              class="elem-job-home radius">
              <div class="visuel-job radius">
                <img :src="require('@/assets/home/job-wallpaper.png')">
              </div>
              <div class="info-job">
                <div class="head-job">
                  <div class="logo-job">
                    <img :src="require('@/assets/home/job-logo.png')">
                  </div>
                  <div class="title-job">
                    <p class="company-name bold">Society's Name</p>
                    <p class="offer-name">Job's Title</p>
                  </div>
                </div>
                <div class="bottom-job">
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Contract
                    </div>
                  </div>
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Location
                    </div>
                  </div>
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_calendar_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Since
                    </div>
                  </div>
                </div>
              </div>
            </splide-slide>
          </splide>
        </div>
        <div class="cont-list" v-if="whichlist == 'Talent'">
          <splide class="list-job" :options="gridOptionsJob" :extensions="extensions">
            <splide-slide v-for="job in fakeElem" :key="job"
              class="elem-job-home radius talent">
              <div class="info-job">
                <div class="head-job">
                  <div class="logo-job">
                    <img :src="require('@/assets/home/job-logo.png')">
                  </div>
                  <div class="title-job">
                    <p class="company-name bold">Talent's name</p>
                  </div>
                </div>
                <div class="bottom-job">
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Contract
                    </div>
                  </div>
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Location
                    </div>
                  </div>
                  <div class="elem-info">
                    <div class="picto">
                      <img :src="require('@/assets/pictos/picto_calendar_darkpurple.svg')" />
                    </div>
                    <div class="label">
                      Since
                    </div>
                  </div>
                </div>
              </div>
            </splide-slide>
          </splide>
        </div>
      </div>
    </div>
    <!-- News -->
    <div class="section" id="news">
      <h2 class="title bold mb3">
        Stay up to date
      </h2>
      <h3 class="fnt-text txt-grey-purple">
        Latest news in quasting.
      </h3>
      <div class="cont-global-news radius mt3">
        <div class="left-news radius">
          <div class="major-news">
            <div class="visuel-news">
              <img class="radius" :src="require('@/assets/home/tmp-news.png')" />
            </div>
            <div class="content-news">
              <div class="cont-source">
                <a class="btn-green violet radius">
                  Source
                </a>
                <div class="time-to-read">
                  {{selectedJob.time}}
                </div>
              </div>
              <div class="title-news bold" v-html="selectedJob.title">
                <!-- {{selectedJob.title}} -->
              </div>
              <div class="text-news fnt-text" v-html="selectedJob.txt">
                <!-- {{selectedJob.txt}} -->
              </div>
            </div>
          </div>
        </div>
        <div class="right-news" id="listnews">
          <div
            class="elem-news radius"
            v-for="job in fakeNews" :key="job._id"
            @click="() => getMaster(job)"
            :class="job._id===selectedJob._id?'current':''"
          >
            <div class="visual-news radius">
              <img :src="require('@/assets/home/tmp-news.png')" />
            </div>
            <div class="resume-news">
              <div class="cont-source">
                <a class="btn-green small radius-small">
                  Source
                </a>
                <div class="time-to-read">
                  {{job.time}}
                </div>
              </div>
              <p class="fnt-text" v-html="job.title"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div id="footer" class="footer"
      v-if="!this.$store.state.auth.loggedIn || this.$route.path === '/signup'"
    >
      <div class="left">
        <div class="logo_foot">
          <router-link to="/">
            <img :src="require('@/assets/logo_black.svg')">
          </router-link>
        </div>
        <div class="social_global">
          <h4>Follow us</h4>
          <div class="cont_rs">
              <div class="rs_elem"></div>
              <div class="rs_elem"></div>
              <div class="rs_elem"></div>
              <div class="rs_elem"></div>
              <div class="rs_elem"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="col_foot">
          <a href="#presentation">Presentation</a>
          <a href="#optimize">Optimize</a>
          <a href="#features">Features</a>
          <a href="#jobs">Jobs</a>
          <a href="#news">News</a>
        </div>
        <div class="col_foot">
          <router-link to="/">Career</router-link>
          <router-link to="/">Press</router-link>
          <router-link to="/">Contact us</router-link>
          <router-link to="/">Legal Notice</router-link>
          <router-link to="/">Vulnerability</router-link>
        </div>
        <div class="col_foot">
          <router-link to="/">Help Center</router-link>
          <router-link to="/">FAQ</router-link>
        </div>
        <div class="col_foot">
          <router-link to="/">Sales contact</router-link>
        </div>
      </div>
    </div>
    <div class="underfooter">
      <div class="inner-underfoot">
        <div class="copyright">
          Copyright © 2021 quasting.com All right reserved.
        </div>
        <div class="legal">
          <a href="#">Conditions of use</a>
          <a href="#">Privacy</a>
          <a href="#">GDPR</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Grid from '@splidejs/splide-extension-grid';
import PerfectScrollbar from 'perfect-scrollbar';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';

export default {
  name: 'StaticHome',
  components: {
    ToggleSwitch,
    Splide,
    SplideSlide,
    // VueScrollmagic,
    // Grid,
  },
  data() {
    return {
      wordFirstPart: [
        'Fintech ',
        'Blockchain ',
        'AI/Data Science ',
        'Cyber Security ',
        'AR/VR',
      ],
      indexWordFirst: 0,
      wordFirst: 'Fintech',
      extensions: {
        Grid,
      },
      timerchiffres: {
        finetech: null,
        blockchain: null,
        ai: null,
        cyber: null,
        ar: null,
        global: null,
      },
      startchiffres: {
        finetech: 0,
        blockchain: 0,
        ai: 0,
        cyber: 0,
        ar: 0,
        global: 0,
      },
      chiffres: {
        finetech: 12000,
        blockchain: 80,
        ai: 42,
        cyber: 80,
        ar: 20,
        global: 60,
      },
      fakeElem: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      selectedJob: {
        _id: 0,
        title: 'Lorem ipsum dolor sit amet maga no consectetur adipiscing elit?',
        txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
        time: '1 mins',
      },
      fakeNews: [
        {
          _id: 0,
          title: 'Lorem ipsum dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '1 mins',
        },
        {
          _id: 1,
          title: 'Ipsum dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '2 mins',
        },
        {
          _id: 2,
          title: 'dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '3 mins',
        },
        {
          _id: 3,
          title: 'sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '4 mins',
        },
        {
          _id: 4,
          title: 'amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '5 mins',
        },
        {
          _id: 5,
          title: 'maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '6 mins',
        },
        {
          _id: 6,
          title: 'elit Lorem ipsum dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '7 mins',
        },
        {
          _id: 7,
          title: 'adipiscing Lorem ipsum dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '8 mins',
        },
        {
          _id: 8,
          title: 'consectetur Lorem ipsum dolor sit amet maga no consectetur adipiscing elit?',
          txt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit 
amet orci a felis vulputate suscipit sed quis velit. Lorem ipsum dolor
sit amet, consectetur adipiscing elit. Donec sit amet orci a felis
vulputate suscipit sed quis velit.`,
          time: '9 mins',
        },
      ],
      timeOutIds: [],
      featureSelected: 'company',
      whichlist: 'Job',
      gridOptions: {
        arrows: false,
        autoplay: true,
        grid: {
          rows: 2,
          cols: 3,
          gap: {
            row: '1.5vh',
            col: '1.5vh',
          },
        },
      },
      gridOptionsJob: {
        arrows: false,
        autoplay: true,
        grid: {
          rows: 2,
          cols: 3,
          gap: {
            row: '1.5vh',
            col: '1.5vh',
          },
        },
      },
    };
  },
  methods: {
    selectFeature(string) {
      console.log('string : ', string);
      this.featureSelected = string;
    },
    updateList(elem) {
      console.log(elem);
      if (!elem.target.checked) {
        this.whichlist = 'Job';
      } else {
        this.whichlist = 'Talent';
      }
    },
    getMaster(job) {
      this.selectedJob = job;
    },
    animateChiffre() {
      this.timerchiffres.finetech = setInterval(() => {
        if (this.startchiffres.finetech === this.chiffres.finetech) {
          clearInterval(this.timerchiffres.finetech);
        } else {
          this.startchiffres.finetech += 120;
        }
      }, 50);

      this.timerchiffres.blockchain = setInterval(() => {
        if (this.startchiffres.blockchain === this.chiffres.blockchain) {
          clearInterval(this.timerchiffres.blockchain);
        } else {
          this.startchiffres.blockchain += 1;
        }
      }, 50);

      this.timerchiffres.ai = setInterval(() => {
        if (this.startchiffres.ai === this.chiffres.ai) {
          clearInterval(this.timerchiffres.ai);
        } else {
          this.startchiffres.ai += 1;
        }
      }, 50);

      this.timerchiffres.cyber = setInterval(() => {
        if (this.startchiffres.cyber === this.chiffres.cyber) {
          clearInterval(this.timerchiffres.cyber);
        } else {
          this.startchiffres.cyber += 1;
        }
      }, 50);

      this.timerchiffres.ar = setInterval(() => {
        if (this.startchiffres.ar === this.chiffres.ar) {
          clearInterval(this.timerchiffres.ar);
        } else {
          this.startchiffres.ar += 1;
        }
      }, 50);

      this.timerchiffres.global = setInterval(() => {
        if (this.startchiffres.global === this.chiffres.global) {
          clearInterval(this.timerchiffres.global);
        } else {
          this.startchiffres.global += 1;
        }
      }, 50);
    },
    updateWordFirst() {
      this.wordFirst = this.wordFirstPart[this.indexWordFirst];
      this.indexWordFirst += 1;
      if (this.indexWordFirst >= this.wordFirstPart.length) {
        this.indexWordFirst = 0;
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.loggedIn) {
      try {
        if (this.$store.state.auth.type === 'Employee') {
          this.$router.push('/myCompany');
        } else {
          this.$router.push('/myTalent');
        }
      } catch (error) {
        this.$router.push('/');
      }
    } else {
      // this.animWord();
      const ps = new PerfectScrollbar('#listnews', {
        // wheelPropagation: false,
        wheelSpeed: 1,
        suppressScrollX: true,
      });
      ps.update();

      const scene2 = this.$scrollmagic.scene({
        triggerElement: '#triggerAnimeChiffres',
        triggerHook: 0.5,
        duration: 800,
      });

      setTimeout(() => {
        this.$scrollmagic.addScene(scene2);
      }, 500);

      scene2.on('enter', () => {
        console.log('START');
        this.animateChiffre();
      });

      scene2.on('leave', () => {
        clearInterval(this.timerchiffres.finetech);
        clearInterval(this.timerchiffres.blockchain);
        clearInterval(this.timerchiffres.ai);
        clearInterval(this.timerchiffres.cyber);
        clearInterval(this.timerchiffres.ar);
        clearInterval(this.timerchiffres.global);
        this.startchiffres.finetech = 0;
        this.startchiffres.blockchain = 0;
        this.startchiffres.ai = 0;
        this.startchiffres.cyber = 0;
        this.startchiffres.ar = 0;
        this.startchiffres.global = 0;
      });

      setInterval(() => {
        this.updateWordFirst();
      }, 1500);
    }
  },
  beforeDestroy() {
    this.timeOutIds.forEach((id) => {
      clearTimeout(id);
    });
  },
  props: ['tab'],
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  body{
    overflow-y: auto !important;
    overflow-x: hidden;
    color: $text_color;
    background-color: #F8F8F8 ;
  }
  #main{
    height: auto !important;
    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }
    @media screen and (max-width: 1300px) {
      max-width: 1000px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 900px;
    }
  }
  #globalcont{
    height: auto !important;
    overflow: visible !important;
  }
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .fnt-text{
    font-size: 2vh;
    line-height: 1.2;
    &.bigger{
      font-size: 2.8vh;
    }
  }
  .mw60{
    display: block;
    max-width: 55%;
    margin: 0 auto;
  }
  .center{
    text-align: center;
  }
  .bold{
    font-family: 'MontserratBold';
  }
  .uppercase{
    text-transform: uppercase;
  }
  .title{
    font-size: 6vh;
    line-height: 1.2;
    &.bigger{
      font-size: 8vh;
    }
  }
  .subtitle{
    font-size: 3vh;
  }
  .txt-violet{
    color: $logo_color;
  }
  .txt-green{
    color: $green_color;
  }
  .txt-grey-purple{
    color: #7b7486;
  }
  .txt-white{
    color: #ffffff;
  }

  .op50{
    opacity: 0.5;
  }

  .mb2{
    margin-bottom: 2vh;
  }
  .mb3{
    margin-bottom: 3vh;
  }
  .mb8{
    margin-bottom: 8vh;
  }
  .mb10{
    margin-bottom: 10vh;
  }

  .mt2{
    margin-top: 2vh;
  }
  .mt3{
    margin-top: 3vh;
  }
  .mt6{
    margin-top: 6vh;
  }
  .mt8{
    margin-top: 8vh;
  }
  .mt10{
    margin-top: 10vh;
  }

  .pl5v{
    padding-left: 5vh;
  }

  .cont-btn-home{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .mention{
      padding-top: 0.5vh;
      width: 22%;
      text-align: center;
      font-size: 1vh;
    }
  }

  .cont-btn{
    &.just-right{
      width: 100%;
      justify-content: flex-start;
    }
  }
  .btn-green{
    &.big{
      font-size: 2vh;
      padding: 1.5vh 3vh;
    }
  }

  .btn-line{
    position: relative;
    font-size: 2vh;
    font-weight: 600;
    cursor: pointer;
    color: $logo_color;
    &:after{
      content: '';
      position:absolute;
      bottom: 0;
      left: 50%;
      transform: translateY(200%) translateX(-50%);
      width:100%;
      height: 0.2vh;
      background-color: $logo_color;
      transition: width 0.3s;
    }
    &:hover{
      &::after{
        width: 0;
      }
    }
    &.green{
      color: $green_color;
      &:after{
        background-color: $green_color;
      }
    }
  }

  .btn-violet{
    display: block;
    border: 0.1vh solid $logo_color;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 0.3s;
    font-size: $fnt-14;
    padding: 1vh 2vh;
    cursor: pointer;
    &.big{
      font-size: 2vh;
      padding: 1.5vh 3vh;
    }
    &:hover{
      background-color: $logo_color;
    }
  }

  .invite-scroll{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display:flex;
    flex-direction: column;
    align-items: center;
    span{
      display: block;
      font-size: 1.8vh;
      font-weight: 600;
    }
    .cont-elem-scroll{
      position: relative;
      height: 5vh;
      margin-top: 1vh;
      width: 0.6vh;
      background-color: rgba(140, 50, 225, 0.5);
      .free-scroll{
        position: absolute;
        top: 0;
        left: 0;
        height: 2.5vh;
        width: 0.6vh;
        background-color: $logo_color;
        animation: 1s ease-in-out 1s infinite alternate scroll_invite;
      }
    }
  }

  @keyframes scroll_invite {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
  }

  .static_home{
    width: 100%;
  }
  .section{
    position: relative;
    width: 100%;
    min-height: 200px;
    margin-top: 15vh;
    &:first-child{
      margin-top: 0;
    }
    &.flex-row{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    &.flex-col{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      .elem-section{
        &:first-child{
          margin-bottom: 2vh;
        }
      }
    }
    &.fm-reverse{
      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        .elem-section{
          &:first-child{
            margin-top: 2vh;
          }
        }
      }
    }
    .small_section{
      width: 45%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .big_section{
      width: 55%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      img{
        &.leftdecal{
          transform: translateX(-15%);
        }
      }
    }
    img{
      &.bigger{
        width: 110%;
        @media screen and (max-width: 1024px) {
          width: 90%;
          margin-left: 5%;
        }
      }
    }
  }
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_splide.scss";

  #anim-word{
    display: flex;
    .cont-word-first{
      transition: all .3s;
    }
  }

  .top-sect-02{
    background-color: $text_color;
    width: 100%;
    .big_section{
      .bigger{
        transform: translateY(-5.5%) translateX(-10%);
      }
    }
    .small_section{
      padding: 0 5vh 0 10vh;
      .cont-btn{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn-violet{
          &.big{
            font-size: 2vh;
            padding: 2vh 6vh;
          }
        }
      }
    }
  }
  .bottom-sect-02{
    align-items: unset;
    .bot-sect-02{
      position: relative;
      flex: 1;
      width: calc(calc(100% - 3vh) / 2);
      margin: 0 1vh;
      background-color: $red_color;
      border-bottom-left-radius: 1vh;
      border-bottom-right-radius: 1vh;
      background-color: $logo_color;
      padding: 4vh 3vh 2vh 4vh;
      &:nth-child(2){
        margin-left: 0;
      }
      h3{
        font-size: 2.5vh;
        margin-bottom: 2vh;
      }
      p{
        margin-bottom: 5vh;
      }
      a{
        &.btn-line{
          position: absolute;
          bottom: 2vh;
          right: 3vh;
        }
      }
    }
  }
  .cont-optimize{
    display: flex;
    flex-wrap: wrap;
    .elem-optimize{
      width: calc(calc(100% - 10vh) / 3);
      margin-right: 5vh;
      &:last-child{
        margin-right: 0;
      }
      h3{
        &.title-optimize{
          font-size: 4vh;
        }
      }
      p{
        }
      .visual-optimize{
        position: relative;
        .bg-visual-opti{
          position: relative;
          width: 100% !important;
        }
        img{
          position: absolute;
        }
        &.visual01{
          img{
            width: 100%;
            left: 0;
            bottom: 0;
          }
        }
        &.visual02{
          img{
            width: 110%;
            left: 0;
            bottom: 0;
          }
        }
        &.visual03{
          img{
            width: 110%;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  .cont-number{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .elem-number{
      width: calc(calc(100% - 30vh) / 6);
      margin-right: 6vh;
      &:last-child{
        margin-right: 0;
      }
      .picto-number{
        width: 7vh;
        img{
          width: 100%;
        }
      }
      h3{
        font-size: 1.8vh;
      }
      .separ{
        height: 0.4vh;
        width: 60%;
        background-color: $logo_color;
        opacity: 0.3;
        margin-bottom: 1vh;
      }
      .number{
        font-size: 6vh;
      }
      .label{
        margin-top: 1vh;
      }
    }
  }

  .sect-features{
    position: relative;
    background-color: $text_color;
    padding-top: 3vh;
    padding-bottom: 6vh;
    .bg_feature{
      z-index: 0;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    h2{
      position: relative;
      z-index: 1;
    }
    .select-feature{
      display:flex;
      justify-content: center;
      flex-wrap: wrap;
      h3{
        &.elem-select-feat{
          position: relative;
          cursor: pointer;
          font-size: 3vh;
          padding: 2vh 0;
          width: 40vh;
          background-color: $green_color;
          border: 0.4vh solid $green_color;
          margin-right: 10vh;
          transition: all .3s;
          &.no-margin{
            margin-right: 0;
          }
          &:hover{
            background-color: #ffffff;
            .arrow-down{
              border-top: 1.5vh solid #ffffff;
            }
          }
          &.active{
            background-color: #ffffff;
            .arrow-down{
              transform: translateY(200%) translateX(-50%);
              border-top: 1.5vh solid #ffffff;
            }
          }
          .arrow-down {
            z-index: 0;
            position:absolute;
            bottom: 0;
            left: 50%;
            transform: translateY(0%) translateX(-50%);
            width: 0;
            height: 0;
            border-left: 1vh solid transparent;
            border-right: 1vh solid transparent;
            border-top: 1.5vh solid $green_color;
            transition: all .3s;
          }
        }
      }
    }
    .cont-feature{
      position: relative;
      max-width: 70%;
      // background-color: $logo-color;
      margin: 4vh auto 0 auto;
      &.company{
        background-color: $logo-color;
        .picto-feature{
          &:after{
            background-color: $green_color !important;
          }
        }
        p{
          color: #ffffff !important;
        }
      }
      &.talent{
        background-color: $green-color;
        .picto-feature{
          &:after{
            background-color: $logo_color !important;
          }
        }
        p{
          color: $text-color !important;
        }
      }
      .cont-feature-list{
        display: none;
        &.active{
          display: block;
        }
        .feature-list{
          padding: 6vh 4vh 4vh 4vh;
          .elem-feature{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 6vh;
            &:last-child{
              margin-bottom: 0;
              .picto-feature{
                &::after{
                  display: none;
                }
              }
            }
            .picto-feature{
              position: relative;
              width: 5vh;
              margin-right: 2vh;
              img{
                width: 100%;
              }
              &:after{
                content: '';
                height: 4vh;
                width: .5vh;
                background-color: $green_color;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) translateY(125%);
              }
            }
            p{
              font-size: 2.6vh;
            }
          }
        }
      }
    }
  }

  .cont-global-offer{
    .cont-switch{
      padding: 0 0 1.5vh 0;
      display: flex;
      justify-content: flex-end;
    }
    .cont-list{
      display: block;
      width: 100%;
      .splide__track{
        padding: 0.5vh;
      }
      .splide__pagination{
        position: absolute;
        top: unset;
        bottom: 0;
        left: unset;
        right: 0;
        transform: translateX(0) translateY(100%);
        width: auto;
        .splide__pagination__page{
          background-color: $text-color;
          opacity: 0.4;
          width: 0.5vh;
          height: 0.5vh;
          transform: scale(1.4);
          &.is-active{
            opacity: 1;
          }
        }
      }
      .list-job{
        position: relative;
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        .elem-job-home{
          // max-width: 20vh;
          background-color: #ffffff;
          width: calc(calc(100% - 3vh) / 3);
          display: flex;
          flex-direction: row;
          &:nth-child(3n){
            margin-right: 0;
          }
          .visuel-job{
            flex: 1;
            position: relative;
            overflow: hidden;
            max-width: 30%;
            img{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              height: 100%;
              width: auto;
            }
          }
          .info-job{
            display: flex;
            flex-direction: column;
            .head-job{
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 2vh 0;
              .logo-job{
                position: relative;
                height: 6vh;
                width: 6vh;
                overflow: hidden;
                border-radius: 50%;
                transform: translateX(-50%);
                img{
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  width: 100%;
                }
              }
              .title-job{
                .company-name{
                  font-size: 2vh;
                  max-width: 26vh;
                  text-overflow: ellipsis;
                  display:block;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .offer-name{
                  max-width: 26vh;
                  text-overflow: ellipsis;
                  display:block;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
            }
            .bottom-job{
              padding-left: 6vh;
              padding-bottom: 2vh;
              .elem-info{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .picto{
                  position: relative;
                  width: 3vh;
                  height: 2vh;
                  img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 100%;
                  }
                }
                .label{
                  font-size: 1.4vh;
                }
              }
            }
          }
          &.talent{
            .info-job{
              .head-job{
                .logo-job{
                  transform: translateX(0);
                  img{
                  }
                }
                .title-job{
                  .company-name{
                  }
                  .offer-name{
                  }
                }
              }
              .bottom-job{
                .elem-info{
                  .picto{
                    img{
                    }
                  }
                  .label{
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cont-global-news{
    padding: 4vh;
    border: 0.2vh solid $logo_color;
    display: flex;
    flex-direction: row;
    .left-news{
      flex: 1;
      background-color: $text-color;
      // min-height: 20vh;
      max-height: 80vh;
      padding: 6vh;
      border: 0.2vh solid $logo-color;
      .major-news{
        .visuel-news{
          position: relative;
          width: 100%;
          overflow: hidden;
          font-size: 0;
          img{
            border: 0.2vh solid $green-color;
            width: calc(100% - 0.4vh);
          }
        }
        .content-news{
          color: #ffffff;
          .cont-source{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 2vh 0;
            align-items: center;
            .time-to-read{
              position: relative;
              font-size: 1.2vh;
              margin-left: 2vh;
              &::before{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) translateX(-200%);
                content: '';
                height: 0.4vh;
                width: 0.4vh;
                border-radius: 50%;
                background-color: $green-color;
              }
            }
          }
          .title-news{
            color: #ffffff;
            font-size: 2.2vh;
            margin-bottom: 2vh;
          }
        }
      }
    }
    .right-news{
      position: relative;
      flex: 1;
      padding: 0 6vh;
      max-height: 80vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .elem-news{
        width: 100%;
        // min-height: 10vh;
        background-color: transparent;
        margin-bottom: 1vh;
        display:flex;
        flex-wrap: wrap;
        cursor: pointer;
        transition: all .3s;
        &:hover{
          background-color: #ffffff;
        }
        &.current{
          background-color: #ffffff;
        }
        .visual-news{
          position: relative;
          flex: 1 auto;
          width: 25%;
          overflow: hidden;
          img{
            height: 100%;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
        .resume-news{
          flex:1 auto;
          width: 75%;
          .cont-source{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 2vh;
            align-items: center;
            .time-to-read{
              position: relative;
              font-size: 1.2vh;
              margin-left: 1vh;
              &::before{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) translateX(-200%);
                content: '';
                height: 0.4vh;
                width: 0.4vh;
                border-radius: 50%;
                background-color: $green-color;
              }
            }
          }
          p{
            padding: 0 2vh 2vh 2vh;
          }
        }
      }
    }
  }

  .footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15vh;
    .left{
      width: 30%;
      .logo_foot{
        width: 20vh;
        margin-bottom: 4.5vh;
        a{
          display: block;
          width: 100%;
          height: 100%;
          font-size: 0;
        }
      }
      .social_global{
        display: flex;
        flex-direction: column;
        text-align: left;
        h4{
          font-size: 2vh;
          margin-bottom: 1vh;
        }
        .cont_rs{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-start;
          .rs_elem{
              height: 3.5vh;
              width: 3.5vh;
              border-radius: 50%;
              background-color: $border_gray;
              margin-left: 1vh;
              cursor: pointer;
              transition: opacity 0.2s;
              &:first-child{
                  margin-left: 0;
              }
              &:hover{
                  opacity: 0.5;
              }
          }
        }
      }
    }
    .right{
      width: 70%;
      // background-color: blue;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 2vh 0 6vh 0;
      .col_foot{
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        a{
          font-size: 1.9vh;
          color: #000;
          text-decoration: none;
          margin-top: 30px;
          font-weight: 600;
          &:hover{
            text-decoration: underline;
          }
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }

  .underfooter{
    color: #ffffff;
    background-color: $logo_color_dark;
    font-size: 2vh;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    .inner-underfoot{
      margin: 0 auto;
      max-width: 1440px;
      @media screen and (max-width: 1600px) {
        max-width: 1200px;
      }
      @media screen and (max-width: 1300px) {
        max-width: 1000px;
      }
      @media screen and (max-width: 1024px) {
        max-width: 900px;
      }
      padding: 4.5vh 0;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .legal{
        a{
          color: #ffffff;
          text-decoration: none;
          margin-left: 4vh;
          &:first-child{
            margin-left: 0;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
